:root {
  --mainColor: rgba(0,40,60,1);
  --lightColor: rgba(0,123,184,1);
  --mainBlack: rgba(0,0,0,1);
  --mainWhite: rgba(255,255,255,1);
}

.root{
  background-color: var(--mainWhite);
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 1vmin);
  color: black;
  box-sizing: border-box;
  overflow: auto;
}
.mousehover{
  opacity: 1;
}
.mousehover:hover{
  opacity: .5;
  cursor: pointer;
  transition: all 1s ease-in-out;
}
.common_title {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  align-items: flex-start;
  letter-spacing: -0.02em;
  color: #171717;
  display: flex;
  flex-direction: row;
}
.row_spacebetween {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.common_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 12px;
  min-width: 130px;
  height: 40px;
  background: #6366F1;
  border-radius: 5px;
  margin-left: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}