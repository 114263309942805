.wordslist_root_{
    position: relative;
    flex:1;
    width: 100vw;
    display: flex;
    box-sizing: border-box;
}
.wordslist_root{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(10px + 1vmin);
    color: black;
    box-sizing: border-box;
    overflow: auto;
    padding: 0px min(112px,10vw);
  }
.wordslist_header {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top:30px;
}
.wordslist_header_left {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    flex-direction: row;
}
.wordslist_header_right {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    flex-direction: row;
}
.wordslist_searchcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px;
    gap: 12px;
    width: min(343px,30vw);
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    flex: none;
}
.wordslist_searchtxt {
    flex: 1;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.wordslist_filterbutton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 44px;
    height: 39px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    margin-left: 12px;
}
.wordslist_addbutton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 130px;
    height: 40px;
    background: #6366F1;
    border-radius: 5px;
    margin-left: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}
.wordslist_deletebutton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 130px;
    height: 40px;
    background: #ED6060;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}
.wordslist_maincontainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: 20px 0px;
    box-sizing: border-box;
}
.wordslist_table_header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #ABABAB;
    padding-bottom: 5px;
}
.wordslist_table_header_unselected {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #737373;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 1px;
}
.wordslist_table_body_{
    position: relative;
    flex:1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-sizing: border-box;
}
.wordslist_table_body {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-sizing: border-box;
}
.wordslist_table_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border-bottom: 1px solid #ABABAB;
    padding: 5px 0px;
}
.wordslist_table_column {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wordslist_table_viewbutton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: #6366F1;
    border-radius: 6px;
    max-width: 112px;
    flex:1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
}
.wordslist_check_box_unselected {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 1.5px solid #D4D4D4;
    border-radius: 4px;
}
.wordslist_check_box_selected {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    background: #7679F2;
    border: 1.5px solid #D4D4D4;
    border-radius: 4px;
}
.wordslist_table_check_container {
    flex:1;
    max-width: 89px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wordslist_table_footer{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #212529;
    user-select: none;
    margin-top: 20px;
}
.wordslist_table_footer_pageinput{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 65px;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 4px;
}
.wordslist_table_footer_sizedropdown {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    isolation: isolate;
    width: 97px;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    margin-right: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6C757D;
    user-select: none;
    position: relative;
}
.wordslist_table_footer_sizedropdown:hover .wordslist_table_footer_size {
    display: flex;
}
.wordslist_table_footer_size {
    display: flex;
    flex-direction: column;
    user-select: none;
    position: absolute;
    bottom: 100%;
    left:0;
    width: 100%;
    border: 1px solid #212529;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.15));
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #212529;
    z-index: 10;
    background-color: white;
    display: none;
}
.wordslist_table_footer_sizeoption {
    user-select: none;
    padding: 3px 12px;
}
.wordslist_table_footer_size_boxshadow{
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 1e-06);
    box-shadow: 0px 0px 0px 4px rgba(13, 110, 253, 0.25);
    border-radius: 4px;
}
.wordslist_table_footer_pageselection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 16px;
}
.wordslist_table_footer_pageleftarrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    background: rgba(255, 255, 255, 1e-06);
    border: 1px solid #DEE2E6;
    border-radius: 4px 0px 0px 4px;
}
.wordslist_table_footer_pagerightarrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    background: rgba(255, 255, 255, 1e-06);
    border: 1px solid #DEE2E6;
    border-radius: 0px 4px 4px 0px;
}
.wordslist_table_footer_page_unselected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    background: rgba(255, 255, 255, 1e-06);
    border: 1px solid #DEE2E6;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #0D6EFD;
    user-select: none
}
.wordslist_table_footer_page_selected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    background: #0D6EFD;
    border: 1px solid #0D6EFD;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #FFFFFF;
    user-select: none
}
.wordlist_table_word {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #171717;
}
.wordlist_table_date{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #171717;
}