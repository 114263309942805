.accountmanage_root_{
    position: relative;
    flex:1;
    width: 100vw;
    display: flex;
    box-sizing: border-box;
}
.accountmanage_root{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(10px + 1vmin);
    color: black;
    box-sizing: border-box;
    overflow: auto;
    padding: 0px min(112px,10vw);
  }
  .accountmanage_text_1{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #747980;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap:20px;
    align-items: center;
    justify-content: flex-start;
  }