.login_root{
    background-color: var(--mainWhite);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: black;
    box-sizing: border-box;
    overflow: auto;
  }
  
.login_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 488px;
    border: 1px solid #D4D4D4;
    backdrop-filter: blur(30px);
    border-radius: 12px;
    padding: 20px;
}
.login_logo {
    width: 48px;
    height: 48px;
    background: #171717;
    opacity: 0.2;
    border-radius: 3px;  
}
.login_title {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #171717;
    margin-top: 32px;
    margin-bottom: 12px;
}
.login_subtitle {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #171717;
    margin-top: 20px;
}
.login_password {
    box-sizing: border-box;
    width: 100%;
    position: relative;
}
.login_input {
    box-sizing: border-box;
    width: 100%; 
    border: 1px solid #E5E5E5;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    color: #000000;
    padding: 10px 12px 10px 12px;
    margin-top: 4px;
}
.login_mask_icon {
    position: absolute;
    top: 11px;
    right: 18px;
}
.login_forgetpassword_container {
    display: flex;
}
.login_forgetpassword {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #6366F1;
}
.login_remember_container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
.login_remember_box {
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 1.5px solid #D4D4D4;
    border-radius: 4px;
}
.login_remember_txt {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #171717;
    margin-left: 8px;
}
.login_button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: 72px;
    height: 48px;
    background: #6366F1;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 30px;
}
.login_error {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: red;
}