.menu_root {
    width: 100vw;
    height: 68px;
    display: flex;
    flex-direction: row;
    padding: 18px min(112px,10vw);
    justify-content: space-between;
    align-items: center;
    z-index: 5;
}
.menu_icon {
    background: #171717;
    opacity: 0.2;
    border-radius: 3px;
    width: 32px;
    height: 32px;
}
.menu_container_1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.menu_item_selected {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4338CA;
    margin-left: 40px;
}
.menu_item_unselected {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #171717;
    margin-left: 40px;
}
.menu_logout_button {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6366F1;
    margin-left: 20px;
}
.menu_username {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #171717;
    margin: 0px 9px;
}
.menu_setting {
    position: absolute;
    top: 100%;
    right: 0px;
    padding: 10px 16px 0px 16px;
    background: #FFFFFF;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    flex-direction: column;
}
.menu_setting_item {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #171717;
    min-width: 160px;
    margin-bottom: 16px;
}
.menu_arrowbutton ~ .menu_setting{
    display: none;
}
.menu_arrowbutton:hover ~ .menu_setting{
    display: flex;
}
.menu_setting:hover {
    display: flex;
}
.menu_setting_item:hover ~ .menu_setting{
    display: flex;
}
.menu_username:hover ~ .menu_setting{
    display: flex;
}