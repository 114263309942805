.worddetail_main {
    flex:1;
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 20px;
}
.worddetail_left {
    display: flex;
    flex: 5;
    box-sizing: border-box;
    margin-right: 10%;
    flex-direction: column;
    justify-content: flex-start;
}
.worddetail_right {
    display: flex;
    flex: 4;
    flex-direction: column;
    justify-content: flex-start;
}
.worddetail_subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #171717;
    border-bottom: 1px solid #ABABAB;
    padding: 4px 0px;
}
.worddetail_imagecontainer {
    width: 100%;
    background: #F8F8F8;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 20px;
    position: relative;
}
.worddetail_container1_row {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #171717;
    margin-top: 4px;
}
.worddetail_container1_row_key {
    display: flex;
    min-width: 161px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    padding: 8px 12px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    height: 36px;
}
.worddetail_container1_row_value {
    display: flex;
    flex:1;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    padding: 8px 12px;
    margin-left: 4px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    height: 36px;
}