.wordedit_removeicon {
    position: absolute;
    left: 101%;
}
.wordedit_dragicon {
    position: absolute;
    right: 101%;
}
.wordedit_additembutton {
    border: 1px solid #6366F1;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    color: #6366F1;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    margin-right: 8px;
}
.wordedit_uploaditembutton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 6px;
    background: #EEF2FF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #6366F1;
}
.wordedit_textarea {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding: 8px 50px 8px 12px;
    gap: 12px;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: black;
    min-height: 119px;
}
.wordedit_textarea_wordcount {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #737373;
    position: absolute;
    right: 10px;
    top: 10px;
}
.worddetail_drag_txt {
    position: absolute;
    top:10px;
    right: 10px;
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #737373;    
}
.wordedit_draganddropzone_container {
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.wordedit_draganddropzone {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #262626;
    max-width: calc( 100% - 20px);
}
.wordedit_file_container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    padding: 20px 0px 0px 0px;
    text-align: center;
    margin-top: 10px;
}
.wordedit_file_image {
    width: 84.55px;
    height: 84.55px;
    background: #E5E5E5;
    border-radius: 10.5688px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.wordedit_file_delete_button {
    background: #F9FAFB;
    border: 0.507303px solid #111827;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:10px;
    right:-10px;
}
.worddetail_strokecontainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}